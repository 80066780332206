import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import { Stack } from '@mui/material';

const HeaderLoader = () => (
  <Stack
    flexDirection="row"
    height="3rem"
    alignItems="center"
    justifyContent="space-between"
  >
    {new Array(3).fill().map((_, index) => (
      <Skeleton
        key={index}
        sx={{
          width: '31%',
          height: '2rem',
          borderRadius: '0.25rem',
        }}
        variant="circular"
      />
    ))}
  </Stack>
);

export default HeaderLoader;
