import { Dialog, IconButton, Stack } from '@mui/material';
import React, { useState } from 'react';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { exploreSelectedDate } from 'utils/store';
import { useAtom } from 'jotai';
import dayjs from 'dayjs';

const CalendarPicker = ({ dateAction }) => {
  const [selectedDate, setSelectedDate] = useAtom(exploreSelectedDate);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const disableDatesAfterToday = (date) => {
    const today = new Date();
    return date > today;
  };
  const handleChangeDate = (date) => {
    dateAction(date.$d);
    setSelectedDate(date);
    handleCloseModal();
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleCalendarIconClick = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <IconButton
        onClick={handleCalendarIconClick}
        sx={{
          cursor: 'pointer',
          width: '2rem',
          height: '2rem',
          borderRadius: '50%',
          backgroundColor: '#D9D9D9',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CalendarMonthIcon
          sx={{ transform: 'scale(0.8)' }}
          htmlColor="#2D3B3A"
        />
      </IconButton>
      <Dialog
        open={isModalOpen}
        onClose={handleCloseModal}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateCalendar
            defaultValue={dayjs()}
            value={selectedDate}
            shouldDisableDate={disableDatesAfterToday}
            onChange={handleChangeDate}
          />
        </LocalizationProvider>
      </Dialog>
    </>
  );
};

export default CalendarPicker;
