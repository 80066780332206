import React from 'react';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';
import { Stack } from '@mui/material';
import { useAtom } from 'jotai';
import { exploreSearchBar } from 'utils/store';

const SearchBar = ({ placeholder, onSearch }) => {
  const [searchTerm, setSearchTerm] = useAtom(exploreSearchBar);

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };
  const handleReset = () => {
    setSearchTerm('');
    return onSearch('');
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    document?.activeElement.blur();
    return onSearch(searchTerm);
  };

  return (
    <Stack
      component={'form'}
      onSubmit={handleSubmit}
      sx={{
        position: 'relative',
        flexFlow: 'row nowrap',
        width: 'fit-content',
        alignItems: 'center',
        borderRadius: '3rem',
      }}
    >
      <InputBase
        id="explore-search-bar"
        placeholder={placeholder}
        value={searchTerm}
        onChange={handleChange}
        sx={{
          width: '15rem',
          height: '2rem',
          padding: '0.25rem',
          fontWeight: 600,
          fontSize: '1rem',
          lineHeight: '1rem',
          fontFamily: 'IBM Plex Sans',
          '& .MuiInputBase-input': {
            paddingY: '0.25rem',
            marginLeft: '0.5rem',
            borderBottom: '1px solid #D9D9D9',
          },
          '& .MuiInputBase-input:focus': {
            borderBottom: '1px solid #2b7979',
          },
        }}
        inputProps={{
          'aria-label': 'search',
        }}
        startAdornment={
          <SearchIcon
            onClick={handleSubmit}
            sx={{
              cursor: 'pointer',
            }}
            htmlColor="#2D3B3A"
          />
        }
        endAdornment={
          searchTerm ? (
            <CancelIcon
              onClick={handleReset}
              sx={{
                marginRight: '0.75rem',
                cursor: 'pointer',
                fontSize: '1rem',
              }}
              htmlColor="#D9D9D9"
            />
          ) : null
        }
      />
    </Stack>
  );
};

export default SearchBar;
