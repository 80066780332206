import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import { Divider, Stack } from '@mui/material';

const dividerStyle = {
  backgroundColor: '#2b797925',
  borderBottomWidth: '1px',
};

const ExploreLoader = () => (
  <Stack width="100%" direction="column" spacing={1}>
    <Skeleton variant="rectangular" width="100%" height={'35vh'} />
    <Divider sx={dividerStyle} />
    {new Array(3).fill(0).map((_, index, array) => (
      <React.Fragment key={`loader-${index}`}>
        <Skeleton variant="rectangular" width="100%" height={'10vh'} />
        {index !== array.length - 1 ? <Divider sx={dividerStyle} /> : null}
      </React.Fragment>
    ))}
  </Stack>
);

export default ExploreLoader;
