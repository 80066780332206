import supabase from '@utils/supabase';
import { useMutation } from 'react-query';
import { useAtom } from 'jotai';
import {
  exploreArticles,
  exploreThemes,
  explorePagination,
  exploreInitialLoader,
} from 'utils/store';
import { useState } from 'react';
import { getSupabaseDate } from 'utils/date';
import endOfDay from 'date-fns/endOfDay';
import format from 'date-fns/format';

const ITEMS_PER_PAGE = 20;

const fetchThemes = async () => {
  const { data, error } = await supabase
    .from('theme')
    .select('*')
    .neq('theme_id', 7)
    .order('rank', { ascending: true });

  if (error) throw error;

  const defaultTheme = {
    theme_id: 99,
    themes: {
      en: 'Latest',
    },
  };
  data.unshift(defaultTheme);
  return data;
};

const useExplorer = () => {
  const [initialLoader, setInitialLoader] = useAtom(exploreInitialLoader);
  const [articles, setArticles] = useAtom(exploreArticles);
  const [themes, setThemes] = useAtom(exploreThemes);
  const [currentPage, setCurrentPage] = useAtom(explorePagination);
  const [stopFetching, setStopFetching] = useState(false);

  const fetchArticlesByFilters = async ({
    theme,
    date,
    keywords,
    isScroll,
  }) => {
    if (isScroll === false) setInitialLoader(true);
    setStopFetching(false);
    const todayDate = new Date();
    todayDate.setDate(todayDate.getDate() - 1);

    let dayMinusOne = endOfDay(todayDate);
    const customQuery = supabase
      .from('feed_view_table')
      .select('*')
      .eq('verified', true)
      .lt('created_at', format(dayMinusOne, "yyyy-MM-dd'T'HH:mm:ss.SSS"));

    if (keywords) {
      const words = keywords.split(' ');
      const nonEmptyWords = words.filter((word) => word.trim() !== ''); // Filter out empty strings
      const formattedWords = nonEmptyWords
        .map((word) => `'${word}'`)
        .join(' | ');
      customQuery.textSearch('fts_doc_en', `${formattedWords}`);
    } else {
      if (theme) {
        if (theme !== 99) customQuery.eq('theme_id', theme);
      }
      if (date) {
        const selectedDate = getSupabaseDate(date);
        const previousDate = new Date(selectedDate);
        previousDate.setDate(selectedDate.getDate() - 1);

        let endDate = endOfDay(previousDate);
        customQuery.lt(
          'created_at',
          format(endDate, "yyyy-MM-dd'T'HH:mm:ss.SSS"),
        );
      }
    }

    customQuery.order('created_at', { ascending: false });
    if (isScroll) {
      customQuery.range(
        (currentPage - 1) * ITEMS_PER_PAGE,
        currentPage * ITEMS_PER_PAGE - 1,
      );
    } else {
      customQuery.range(0, ITEMS_PER_PAGE - 1);
    }

    const { data, error } = await customQuery;

    if (error) throw error;

    return { data, isScroll };
  };

  const appendArticles = (newArticles) => {
    setArticles((prevArticles) => {
      const uniqueNewArticles = newArticles.filter(
        (newArticle) =>
          !prevArticles.some(
            (article) => article.post_id === newArticle.post_id,
          ),
      );
      return [...prevArticles, ...uniqueNewArticles];
    });
  };

  return {
    fetchArticlesByFilters: useMutation(
      (params) => fetchArticlesByFilters(params),
      {
        onSuccess: ({ data, isScroll }) => {
          setInitialLoader(false);

          if (isScroll) {
            appendArticles(data);
            setCurrentPage((prevPage) => prevPage + 1);
          } else {
            setArticles(data);
            setCurrentPage(1);
          }

          if (data.length === 0 || data.length < ITEMS_PER_PAGE) {
            setStopFetching(true);
          }
        },
        onError: () => {},
      },
    ),
    fetchThemes: useMutation(fetchThemes, {
      onSuccess: (data) => {
        setThemes(data);
      },
      onError: () => {},
    }),
    initialLoader,
    stopFetching,
    articles,
    themes,
  };
};

export default useExplorer;
