import React from 'react';
import { Link } from 'gatsby';
import { Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { formatDistanceStrict, parseISO } from 'date-fns';
import { formatDate } from '@utils/date';
import { language } from 'utils/i18n';
import { event } from 'utils/analytics';
import useUser from 'hooks/useUser';

const ExploreArticles = ({ article }) => {
  const { session } = useUser();
  const { post_id, medias, title, themes, created_at } = article;

  let articleDate = parseISO(created_at);
  const timeElapsed = formatDistanceStrict(articleDate, new Date(), {
    roundingMethod: 'floor',
  });

  return (
    <Link
      to={`/post/${post_id}`}
      onClick={() => {
        event('post_visited_from_explore', {
          post_id: post_id,
          date: String(new Date()),
          user_id: session?.user?.id ? session.user.id : 'anonymous',
        });
      }}
    >
      <Stack direction="row" height="6rem" position="relative">
        <Box
          component="img"
          src={medias[0]}
          alt={title[language]}
          sx={{
            aspectRatio: '1.15/1',
            height: '100%',
            objectFit: 'cover',
            fontFamily: 'IBM Plex Sans',
          }}
        />
        <Stack justifyContent="space-between" width="100%" height="100%">
          <Stack marginLeft={1}>
            <Stack direction="row" justifyContent="space-between">
              <Typography
                sx={{
                  color: '#2b7979',
                  fontWeight: 600,
                  fontSize: '0.85rem',
                  lineHeight: '0.85rem',
                }}
              >
                | {themes[language]}
              </Typography>
              <Stack direction="row" alignItems="center">
                <Typography
                  sx={{
                    color: '#a6a6a6',
                    fontSize: '0.85rem',
                    lineHeight: '0.85rem',
                    fontWeight: 500,
                    marginRight: 0.25,
                  }}
                >
                  {timeElapsed}
                </Typography>
                <AccessTimeIcon
                  sx={{ fontSize: '0.85rem', color: '#a6a6a6' }}
                />
              </Stack>
            </Stack>
            <Typography
              sx={{
                display: '-webkit-box',
                WebkitLineClamp: 3,
                WebkitBoxOrient: 'vertical',
                height: '3.30rem',
                fontSize: '1rem',
                lineHeight: '1.10rem',
                marginTop: 1,
                overflow: 'hidden',
              }}
            >
              {title[language]}
            </Typography>
          </Stack>
          <Typography
            sx={{
              position: 'absolute',
              bottom: 0,
              right: 0,
              color: '#a6a6a6',
              fontSize: '0.65rem',
              lineHeight: '0.65rem',
              display: 'flex',
              fontWeight: 500,
              justifyContent: 'flex-end',
            }}
          >
            {formatDate(created_at, 1)}
          </Typography>
        </Stack>
      </Stack>
    </Link>
  );
};

export default ExploreArticles;
