import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import useUser from '@hooks/useUser';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { App } from '@layouts';
import useABTesting from 'hooks/useABTesting';
import useExplorer from 'hooks/useExplore';
import HighlightCard from 'components/explore/HighlighCard';
import CircleLoader from 'components/loading/CircleLoader';
import { ButtonBase, Container, Divider, Stack } from '@mui/material';
import ExploreLoader from 'components/loading/ExploreLoader';
import SearchBar from 'components/explore/SearchBar';
import ExploreArticles from 'components/explore/ExploreArticles';
import CalendarPicker from 'components/explore/CalendarPicker';
import ThemesBar from 'components/explore/ThemesBar';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import {
  exploreCurrentFilters,
  exploreSearchBar,
  exploreSelectedDate,
  exploreThemesBar,
} from 'utils/store';
import { useAtom } from 'jotai';
import dayjs from 'dayjs';
import ThemesLoader from 'components/loading/ThemesLoader';
import { event } from 'utils/analytics';

const Explore = () => {
  const { t } = useABTesting({}, 'pages.explore');
  const { session } = useUser();
  const {
    fetchArticlesByFilters: {
      mutate: getArticles,
      isLoading: isArticlesLoading,
      isError,
    },
    fetchThemes: { mutate: getThemes, isLoading: isThemesLoading },
    initialLoader,
    stopFetching,
    articles,
    themes,
  } = useExplorer();
  const [, setThemesBar] = useAtom(exploreThemesBar);
  const [, setSeachTerm] = useAtom(exploreSearchBar);
  const [, setSelectedDate] = useAtom(exploreSelectedDate);
  const [currentFilters, setCurrentFilters] = useAtom(exploreCurrentFilters);

  const handleFetchArticles = (params) => {
    if (params.isScroll === false) {
      const scrollableDiv = document?.getElementById('scrollableDiv');
      scrollableDiv?.scrollTo({ top: 0, behavior: 'smooth' });
    }

    getArticles({ ...currentFilters, ...params, isScroll: false });
    if (params.theme === 99) {
      setCurrentFilters({ theme: null });
    } else {
      setCurrentFilters((prevFilters) => ({ ...prevFilters, ...params }));
    }
  };

  const resetFetchArticles = () => {
    getArticles({ theme: null, date: null, keywords: null, isScroll: false });
    setCurrentFilters({ theme: null, date: null, keywords: null });
    setThemesBar(0);
    setSeachTerm('');
    setSelectedDate(dayjs());
  };

  const onRefresh = async (resolve) => {
    await getArticles({ ...currentFilters, isScroll: false });
    return resolve();
  };

  useEffect(() => {
    if (!session) {
      navigate('/');
    }
    if (!articles.length) getArticles({ ...currentFilters, isScroll: false });
    if (!themes.length) getThemes();
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (
        entries[0].isIntersecting &&
        !isArticlesLoading &&
        articles.length > 0 &&
        !initialLoader &&
        !stopFetching
      ) {
        getArticles({ ...currentFilters, isScroll: true }); // fetch more articles
      }
    });

    observer.observe(document.querySelector('#scroll-trigger'));

    return () => {
      observer.disconnect();
    };
  }, [isArticlesLoading, articles]);

  const appendHeader = (
    <Stack direction="column">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        paddingBottom={currentFilters.keywords ? 1.5 : 0}
      >
        <SearchBar
          placeholder={t('search')}
          onSearch={(value) => {
            handleFetchArticles({ keywords: value, isScroll: false });
            event('explore_search_filter', {
              user_id: session?.user?.id ? session.user.id : 'anonymous',
              date: String(new Date()),
              keywords: value,
            });
          }}
        />
        {!currentFilters.keywords ? (
          <CalendarPicker
            dateAction={(value) => {
              handleFetchArticles({
                date: value,
                isScroll: false,
              });
              event('explore_date_filter', {
                user_id: session?.user?.id ? session.user.id : 'anonymous',
                date: String(new Date()),
                dateSelected: String(value),
              });
            }}
          />
        ) : null}
      </Stack>
      {!currentFilters.keywords ? (
        themes.length && !isThemesLoading ? (
          <ThemesBar
            themes={themes}
            onClick={(value) => {
              handleFetchArticles({
                theme: value,
                isScroll: false,
              });
              event('explore_theme_filter', {
                user_id: session?.user?.id ? session.user.id : 'anonymous',
                date: String(new Date()),
                themeSelected: value,
              });
            }}
          />
        ) : (
          <ThemesLoader />
        )
      ) : null}
      {currentFilters.theme ||
      currentFilters.date ||
      currentFilters.keywords ? (
        <ButtonBase
          direction="row"
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            width: 'fit-content',
            bottom: '-2.25rem',
            right: '0',
            height: '2.25rem',
            backgroundColor: '#fff',
            borderBottomLeftRadius: '1rem',
            padding: '0.75rem',
          }}
          variant="textBold"
          onClick={resetFetchArticles}
        >
          <Typography
            sx={{ fontSize: '1rem', textTransform: 'uppercase' }}
            variant="textBold"
            color="primary"
          >
            {' '}
            {t('reset')}
          </Typography>{' '}
          <FilterAltOffIcon color="primary" />
        </ButtonBase>
      ) : null}
    </Stack>
  );

  return (
    <App
      titleSEO={t('title')}
      descriptionSEO={t('description')}
      onRefresh={onRefresh}
      appendHeader={appendHeader}
      customHeaderHeight={!currentFilters.keywords ? '144px' : '108px'}
    >
      <Container maxWidth="sm">
        <Stack spacing={1}>
          {!initialLoader ? (
            articles?.length ? (
              articles.map((article, index) => {
                return (
                  <React.Fragment key={article.post_id}>
                    {index === 0 ? (
                      <HighlightCard article={article} />
                    ) : (
                      <ExploreArticles article={article} />
                    )}
                    {index !== articles.length - 1 ? (
                      <Divider
                        key={`divider-${index}`}
                        sx={{
                          backgroundColor: '#2b797925',
                          borderBottomWidth: '1px',
                        }}
                      />
                    ) : null}
                  </React.Fragment>
                );
              })
            ) : (
              <Typography component="p" variant="boldContent">
                {t('no_articles_found')}
              </Typography>
            )
          ) : (
            <ExploreLoader />
          )}

          {isError ? (
            <Typography component="p" variant="boldContent" textAlign="center">
              {t('error')}
            </Typography>
          ) : null}
          {!initialLoader && isArticlesLoading ? (
            <Stack sx={{ my: 2 }}>
              <CircleLoader notFixed />
            </Stack>
          ) : null}
          {stopFetching && !initialLoader && articles.length !== 0 ? (
            <Typography component="p" variant="boldContent" textAlign="center">
              {t('no_more_articles')}
            </Typography>
          ) : null}

          {/* Trigger the fetch when scrolling */}
          <Box
            component="span"
            id="scroll-trigger"
            style={{
              display: stopFetching || initialLoader ? 'none' : 'block',
              height: '1.5rem',
            }}
          />
        </Stack>
      </Container>
    </App>
  );
};

export default Explore;
