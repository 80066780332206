import { Tab, Tabs, Typography } from '@mui/material';
import { useAtom } from 'jotai';
import React from 'react';
import { language } from 'utils/i18n';
import { exploreThemesBar } from 'utils/store';

const ThemesBar = ({ themes, onClick }) => {
  const [value, setValue] = useAtom(exploreThemesBar);

  const handleChange = (e, value) => {
    setValue(value);
  };

  return (
    <Tabs
      value={value}
      textColor="inherit"
      onChange={handleChange}
      variant="scrollable"
      scrollButtons="auto"
    >
      {themes?.map(({ theme_id, themes }) => (
        <Tab
          key={theme_id}
          label={
            <Typography variant="body1" noWrap sx={{ fontSize: 14 }}>
              {themes[language]}
            </Typography>
          }
          onClick={() => onClick(theme_id)}
        />
      ))}
    </Tabs>
  );
};

export default ThemesBar;
