import { Box, Stack, Typography } from '@mui/material';
import { Link } from 'gatsby';
import useUser from 'hooks/useUser';
import React from 'react';
import { event } from 'utils/analytics';
import { language } from 'utils/i18n';

const HighlightCard = ({ article }) => {
  const { session } = useUser();
  const { post_id, medias, title, themes } = article;
  return (
    <Link
      to={`/post/${post_id}`}
      onClick={() => {
        event('post_visited_from_explore', {
          post_id: post_id,
          date: String(new Date()),
          user_id: session?.user?.id ? session.user.id : 'anonymous',
        });
      }}
    >
      <Stack
        sx={{
          position: 'relative',
          width: '100%',
          height: '25vh',
          color: 'white',
        }}
      >
        <Stack
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: '0.75rem',
          }}
        >
          <Typography variant={'h5'}>{themes[language]}</Typography>
        </Stack>
        <Box
          component="img"
          src={medias[0]}
          alt={title[language]}
          sx={{ width: '100%', height: '100%', objectFit: 'cover' }}
        />
        <Typography
          variant={'h4'}
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            textAlign: 'center',
            width: '100%',
            padding: '0.75rem',
            background:
              'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(83, 83, 83, 0.35) 47.92%, #000 91.15%)',
          }}
        >
          {title[language]}
        </Typography>
      </Stack>
    </Link>
  );
};

export default HighlightCard;
